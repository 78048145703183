* {
  box-sizing: border-box;
}

body {
  font-family: 'Fira Sans', sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

textarea,
input {
  font-family: 'Fira Sans', sans-serif;
}

.button-main {
  align-items: center;
  background-clip: padding-box;
  background-color: #fa6400;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui, -apple-system, system-ui, 'Helvetica Neue', Helvetica,
    Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  height: 50px;
  justify-content: center;
  line-height: 1.25;
  margin: 0.5rem auto 0;
  min-height: 3rem;
  padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: fit-content;
}

.button-main:disabled {
  background-color: #bdbcbc;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.toast-progress-dark {
  background: rgb(187 186 186) !important;
}

.title {
  font-size: 1.5rem;
  font-weight: 800;
  margin: 1rem auto;
  text-decoration: underline;
  width: fit-content;
}
